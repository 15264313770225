import { useNavigate, useParams } from "react-router-dom"

import { axios } from "../../../axios.cfg"
import { useEffect, useState } from "react"
import { Button, Input } from "antd"

import style from "./phone.note.module.css"
import MyEditor from "../../tools/editor"

export default function PhoneNotesNote(props){
    const param = useParams()
    const noteId = param.note
    const navigate = useNavigate()

    const [noteDetail,setNoteDetail] = useState({})
    const [showConfirm,setShowConfirm] = useState(false)
    useEffect(()=>{
        getNoteDetail()
        // eslint-disable-next-line
    },[noteId])

    return <div className={style.container}>
        <div className={style.editor}>
            <Input value={noteDetail.title} onChange={(e)=>setNoteDetail({...noteDetail,title:e.target.value})} />
            {noteDetail.id&&
            <MyEditor initData={noteDetail.content} backData={(e)=>setNoteDetail({...noteDetail,content:e})} />}
        </div>

        <div>
            <Button onClick={submitEdit}>提交</Button>
            <Button onClick={()=>setShowConfirm(true)}>删除</Button>
        </div>

        {showConfirm&&<div>
                <p>确定删除？</p>
                <Button onClick={()=>setShowConfirm(false)}>取消</Button>
                <Button onClick={()=>deleteNote()}>确定</Button>
        </div>}
    </div>

    function getNoteDetail() {
        axios.get("/api/note",{
            params:{
                noteId
            }
        }).then(({data})=>{
            if(data.status === 200){
                setNoteDetail(data.data)
            }
        }).catch(err=>{})
    }

    function submitEdit(){
        axios.put("/api/note",{
            id:noteDetail.id,
            title:noteDetail.title,
            content:noteDetail.content
        }).then(({data})=>{
            if(data.status === 200){
                alert("修改成功")
            }
        }
        ).catch(err=>{})
    }

    function deleteNote(){
        axios.delete("/api/note",{
            params:{
                id:noteId
            }
        }).then(({data})=>{
            if(data.isok){
                alert("删除成功")
                navigate("/note/"+param.folder)
            }
        }).catch(err=>{})
    }
}