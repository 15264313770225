import React, { useEffect, useState } from "react";
import Header from "../header";
import Calendar from "../tools/calendar";
import style from './index.module.css'
import { PlusCircleFilled } from "@ant-design/icons";
import { axios } from "../../axios.cfg";

export default class Home extends React.Component{

    state={
        date:"a",
        timer:null,

        s2:"",
        s3:"",
        show:false
    }
    componentDidMount(){
        let s1 = "hello,"+new Date().toLocaleDateString();
        this.setState({...this.state,date:s1})
    }
    componentWillUnmount(){
        clearInterval(this.state.timer)
    }


    render(){
        return<div>
            <Header path="/" />
            <div className={style.container}>
                <div className={style.left}>
                    <p>
                        {this.state.date}
                    </p>
                    <div>
                        <p>You were a moment in life that comes and goes,</p>
                        <p className={style.fuck}>A riddle, a rhyme that no one knows.</p>
                    </div>

                    <div>
                        <Message />
                    </div>
                </div>

                <div className={style.right}>
                    <Calendar />
                </div>
            </div>
        </div>
    }

}


function Message(){
    const [showAdd,setShowAdd] = useState(false)
    const [message,setMessage] = useState({
        content:'',
        name:'',
        email:''
    })
    const [list,setList] = useState([])

    useEffect(()=>{
        getList()
    },[])

    return<div>

        <p># 留言板 <PlusCircleFilled onClick={()=>setShowAdd(!showAdd)} /></p>

        {showAdd&&<div className={style.message}>
            <textarea className={style.mesasgeContent} placeholder="内容"
                onChange={e=>setMessage({...message,content:e.target.value})} />
            <input className={style.messageAuthor} placeholder="如何称呼？"
                onChange={e=>setMessage({...message,name:e.target.value})} />
            <input className={style.messageEmail} placeholder="联系方式 仅博主可见"
                onChange={e=>setMessage({...message,email:e.target.value})} />
            <span className={style.messageBtn} onClick={submit}>提交</span>
        </div>}

        <div className={style.list}>
            {list.map((item,index)=><div key={index}>
                <p className={style.itemAuthor}>
                    <span>{item.name}</span>
                </p>
                <p className={style.itemContent}>{item.content}</p>
            </div>)}
        </div>
    </div>

    function submit() {
        if(!message.content||!message.name){
            return alert('内容和称呼不能为空')
        }
        axios.post("/api/message",{
            ...message
        }).then(res=>{
            setShowAdd(false)
            getList()
        }).catch(err=>{
            console.log(err)
        })
    }

    function getList() {
        axios.get("/api/message",{
            params:{
                page:0
            }
        }).then(({data})=>{
            if(data.status === 200){
                setList(data.data)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}