import React from "react"
import FolderList from "./folder.list"
import style from "./index.module.css"
import {axios} from "../../axios.cfg"
import NoteEditor from "./note.editor"

export default class Notes extends React.Component{
    state={
        list:[],
        detail:{
            id:"",
            content:"",
            folder:""
        },
        activeNote:null,
        activeFolder:''
    }

    render(){
        return(
            <div className={style.father}>
                <div className={style.noteContainer}>
                    {/* 左边 */}
                    <div className={style.noteTags}>
                        <FolderList getNotesOfFolder={this.getNotesOfFolder} 
                            setList={this.setList} 
                            createNote={this.createNote}
                            />
                        <div className={style.noteTitles}>
                            {
                                this.state.list.length>0?
                                this.state.list.map(e=>{
                                    return <p key={e.id} className={[style.noteTitle,this.state.activeNote===e.id?style.activeNote:''].join(' ')} 
                                        onClick={()=>this.setDetail(e)}>{e.title}</p>;
                                }):
                                <p className={style.fuck}>EMPTY···</p>
                            }
                        </div>
                    </div>

                    {/* 最右边编辑器 */}
                    <div className={style.editorContainer}>
                        <NoteEditor id={this.state.detail.id} 
                            getNotesOfFolder={this.getNotesOfFolder}
                            folder={this.state.activeFolder} />
                    </div>
                </div>
            </div>
        )
    }

    getNotesOfFolder=(activeFolder)=>{
        axios.post("/api/note/oftag",{
            folder:activeFolder
        }).then(({data})=>{
            this.setState({...this.state,list:data.data,activeFolder})
        }).catch(err=>{})
    }

    setDetail=(e)=>{
        this.setState({...this.state,detail:e,activeNote:e.id})
    }

    setList=(list)=>{
        this.setState({...this.setState,list})
    }


    createNote=(folder)=>{
        this.setState({...this.state,detail:{id:0,content:'添加',folder},activeFolder:folder,activeNote:-1})
    }
}