import React from "react"
import { Link } from "react-router-dom"

export default class RegisterPage extends React.Component{
    render(){
        return <div>
            

            <p>
                已有账号？<Link to={"/login"}>登录</Link>
            </p>
        </div>
    }
}