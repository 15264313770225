import React from "react";

import style from "./calendar.module.css"

function getMatrix(paramDate){
        let date = new Date()
        if(paramDate){
            date = paramDate
        }

        let year = date.getFullYear()
        let month = date.getMonth()+1
        let today = date.getDate()
        let days = [31,28,31,30,31,30,31,31,30,31,30,31] 
        if ( (year % 4 ===0) && (year % 100 !==0 || year % 400 ===0) ) {
                days[1] = 29
        }

        let firstDateObj = new Date(year,date.getMonth(),1)
        let firstDate = firstDateObj.getDay()
        if(firstDate === 0) firstDate=7
        let dayCount = days[date.getMonth()]

  

        let maxtrix = [[0,0,0,0,0,0,0],[0,0,0,0,0,0,0],[0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0],[0,0,0,0,0,0,0],[0,0,0,0,0,0,0]]

        let maxtrixIndex = 0;
        let maxtrixLine = 0;
        for(let i=1;i<firstDate;i++){
            maxtrix[maxtrixLine][maxtrixIndex] = 0;
            maxtrixIndex++;
            if(maxtrixIndex===7){
                maxtrixIndex=0;
                maxtrixLine++;
            }
        }
        for(let i=1;i<=dayCount;i++){
            maxtrix[maxtrixLine][maxtrixIndex] = i;
            maxtrixIndex++;
            if(maxtrixIndex===7){
                maxtrixIndex=0;
                maxtrixLine++;
            }
        }
        return{
            maxtrix,
            year,
            month,
            today
        }
}

export default class Calendar extends React.Component{
    constructor(props){
        super(props);
        this.state=getMatrix()
    }

    state={

    }

    render() {
        return (
            <div className={style.calendar}>
                <p className={style.btns}>
                    <small>{this.state.year+"-"+this.state.month+"-"+this.state.today}</small>
                    <span onClick={this.pre}>◐</span>
                    <span onClick={this.reset}>●</span>
                    <span onClick={this.next}>◑</span>
                </p>
                <div className={style.line}>
                    <p>
                        <span>一</span>
                        <span>二</span>
                        <span>三</span>
                        <span>四</span>
                        <span>五</span>
                        <span>六</span>
                        <span>日</span>
                    </p>
                </div>
                {this.state.maxtrix.map((days,iIndex)=><div className={style.line}  key={iIndex}>
                    <p>
                        {
                            days.map((day,dayIndex)=><span key={dayIndex} 
                                className={this.state.today===day?style.today:""}
                                >
                                {day===0?" ":day}
                            </span>)
                        }
                    </p>
                </div>)}
            </div>
        );
    }

    next=()=>{
        let date = new Date(this.state.year,this.state.month,this.state.today)
        this.setState(getMatrix(date))
    }
    pre=()=>{
        let date = new Date(this.state.year,this.state.month-2,this.state.today)
        this.setState(getMatrix(date))
    }
    reset=()=>{
        let date = new Date()
        this.setState(getMatrix(date))
    }
}