import React from "react"

export default class Chat extends React.Component{
    state={

    }
    render(){
        return <div>
            <div>
                <p>
                    <button>发送</button>
                </p>
            </div>
        </div>
    }
}