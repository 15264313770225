import React from "react"
import { Link } from "react-router-dom"
import style from "./index.module.css"
import MyMessage from "../my-components/message"
import { axios } from "../../axios.cfg"

import { clear } from "../tools"

export default class LoginPage extends React.Component{

    state={
        username:"",
        password:""
    }

    componentDidMount(){
        this.check()
    }

    render(){
        return <div className={style.bg}>
            
            <div className={style.container}>
                <p>
                    <input placeholder="username or email" 
                        onChange={(v)=>this.setState({...this.state,username:v.target.value})} />
                </p>
                <p>
                    <input placeholder="password" type="password"
                        onChange={(v)=>this.setState({...this.state,password:v.target.value})}
                    />
                </p>
                <p className={style.btns}>
                    <button onClick={this.login}
                        className={style.a}
                        >登录</button>
                    <Link to={"/register"} 
                        className={style.a}>注册</Link>
                </p>
            </div>
        </div>
    }

    login=()=>{
        if(!this.state.username){
            MyMessage.fail("用户名不能空！")
            return
        }
        if(!this.state.password){
            MyMessage.fail("密码不能空！")
            return
        }
        axios.post("/api/login",{
            username:this.state.username,
            password:this.state.password
        }).then(({data})=>{
            localStorage.setItem("user",JSON.stringify(data.data.user))
            localStorage.setItem("token",data.data.token)
            window.location.href="/"
        }).catch(err=>{
            MyMessage.fail("登录失败")
        })
    }

    check=()=>{
        clear()
    }
}