import { useEffect, useState } from "react";
import { axios } from "../../../axios.cfg";
import { Button, Input } from "antd";
import Message from "../../my-components/message";

import style from "./css/article.archive.module.css"

function ArticleArchives(){

    const [archives,setArchives] = useState({list:[],total:0,pageSize:18})
    const [toAdd,setToAdd] = useState({show:false,archive:""})
    const [toRename,setToRename] = useState({show:false,archive:{},newName:""})

    useEffect(()=>{
        getAll()
        // eslint-disable-next-line
    },[])

    return<div className={style.container}>
        <p>
            <Button onClick={()=>setToAdd({...toAdd,show:true})}>增加</Button>
        </p>
        <div>
            {archives.list.map(e=><div key={e.id}>
                <span>{e.archive}</span>
                <p>
                    <Button onClick={()=>del(e.id)}>删除</Button>
                    <Button onClick={()=>setToRename({...toRename,show:true,archive:e})}>重命名</Button>
                    <Button>查看文章列表</Button>
                </p>
            </div>)}

            {toAdd.show&&<>
                <AddPage back={(e)=>setToAdd({...toAdd,archive:e})} 
                    cancle={()=>setToAdd({...toAdd,show:false})} 
                    add={()=>add()}
                />    
            </>}

            {toRename.show&&<div className={style.toRename}>
                <div>
                    <Input addonBefore={toRename.archive.archive} 
                        placeholder="输入新的名字"
                        onChange={e=>setToRename({...toRename,newName:e.target.value})} />
                    <Button onClick={rename}>提交</Button>
                    <Button onClick={()=>setToRename({...toRename,show:false})}>取消</Button>
                </div> 
            </div>}
        </div>
    </div>

    function getAll(){
        axios.get("/api/a/archive",{
            params:{
                page:0
            }
        }).then(({data})=>{
            if(data.status===200){
                setArchives({...archives,list:data.data.list,total:data.data.total})
            }
        })
    }

    function add(){
        if(!toAdd.archive){
            Message.fail("请输入合集名")
            return
        }
        axios.post("/api/a/archive",{
            archive:toAdd.archive
        }).then(({data})=>{
            console.log(data)
            if(data.status!==200){
                Message.fail(data.msg)
                return
            }else{
                Message.success("添加成功")
                getAll()
                setToAdd({...toAdd,show:false})
            }
        })
    }

    function del(id){
        axios.delete("/api/a/archive",{
            params:{
                id
            }
        }).then(({data})=>{
            if(data.status!==200){
                Message.fail(data.msg)
                return
            }else{
                Message.success("删除成功")
                getAll()
            }
        })  
    }

    function rename(archive){
        if(!toRename.newName){
            Message.fail("请输入新的名字") 
            return
        }
        axios.put("/api/a/archive",{
            id:toRename.archive.id,
            archive:toRename.newName
        }).then(({data})=>{
            if(data.status!==200){
                Message.fail(data.msg)
                return
            }else{
                Message.success("修改成功")
                getAll()
                setToRename({...toRename,show:false})
            }
        })
    }


}

function AddPage(props){
    return<div className={style.toAdd}>
        <div>
            <Input onChange={e=>props.back(e.target.value)} />
            <Button onClick={props.add}>提交</Button>
            <Button onClick={()=>{props.cancle()}}>取消</Button>
        </div>
    </div>
}

export default ArticleArchives;