import React from "react";
import { axios } from "../../../axios.cfg";
import Confirm from "../../my-components/comfirm";
import Message from "../../my-components/message";
import style from "./root.article.tags.module.css"
import { Button, Input, Popover } from "antd";

export default class RootArticleTags extends React.Component{
    componentDidMount(){
        this.getAllTags();
    }

    state={
        tags:[],
        showAdd:false,
        tagForAdd:"",
        showRename:false,
        tagToRename:{
            id:"",
            tag:""
        },
        newTagName:""
    }

    render(){
        return<div>
            <div>
                <p>
                    <Button onClick={()=>this.setState({...this.state,showAdd:true})}>增加</Button>
                </p>
            </div>


            <div>
                {this.state.tags.map(e=><span key={e.id}>
                    <Popover content={<>
                        <Button onClick={()=>this.rename(e)}>重命名</Button>
                        <Button onClick={()=>this.delete(e.id,e.tag)}>删除</Button>
                    </>}>
                        <Button>{e.tag}</Button>
                    </Popover>
                </span>)}
            </div>

            {this.state.showAdd&&<div className={style.addPage}>
                <div>
                    <input placeholder="输入新标签" onChange={e=>this.setState({...this.state,tagForAdd:e.target.value})}/>
                    <p>
                        <Button onClick={()=>this.setState({...this.state,showAdd:false})}>取消</Button>
                        <Button onClick={()=>this.submitAdd()}>提交</Button>
                    </p>
                   
                </div>

            </div>}

            {this.state.showRename&&<div className={style.renamePage}>
                <div>
                    <p>
                        <Input addonBefore={this.state.tagToRename.tag} placeholder="新的标签名" 
                            onChange={e=>this.setState({...this.state,newTagName:e.target.value})} />
                    </p>
                    <p>
                        <Button onClick={()=>this.setState({...this.state,showRename:false})}>取消</Button>
                        <Button onClick={()=>this.submitRename()}>提交</Button>
                    </p>
                </div>
            </div>}
        </div>
    }


    rename=(e)=>{
        console.log(e)
        this.setState({...this.state,showRename:true,tagToRename:e})
    }

    getAllTags=()=>{
        axios.get("/api/a/tag/tags").then(({data})=>{
            this.setState({...this.state,tags:data})
        }).catch(err=>{
            console.log(err)
        })
    }

    submitAdd=()=>{
        if(this.state.tagForAdd){
            axios.post("/api/a/tag",{
                tag:this.state.tagForAdd
            }).then(({data})=>{
                console.log(data)
                if(data.status===200){
                    Message.success("添加成功")
                    this.getAllTags()
                }else if(data.status===400){
                    Message.fail(data.message)
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }


    submitRename=()=>{
        if(!this.state.newTagName){ 
           Message.fail("请输入新的标签名")
           return
        }
        if(this.state.newTagName){
            axios.put("/api/a/tag",{
                id:this.state.tagToRename.id,
                tag:this.state.newTagName
            }).then(({data})=>{
                if(data.status===200){
                    Message.success("修改成功")
                    this.getAllTags()
                }else if(data.status===400){
                    Message.fail(data.message)
                }
            })
        }
    }

    delete=(id,value)=>{
        Confirm.confirm(value?"确认删除"+value+"?":"确认删除？",()=>{
            console.log(id)
            axios.delete("/api/a/tag",{
                params:{
                    id
                }
            }).then(({data})=>{
                if(data.status===200){
                    Message.success("删除成功")
                    this.getAllTags()
                }
            }).catch(err=>{
                console.log(err)
            })
        },()=>{})
    }
}