import React from "react";
import { axios } from "../../axios.cfg";



export default class GithubOAuth extends React.Component{
    state={
        code:""
    }

    componentDidMount(){
        let re = new URLSearchParams(window.location.search)
        console.log(re)
        let code = re.get("code")
        this.setState({...this.state,code})
    }

    render(){
        return <div>
            github

            <button onClick={()=>this.getUserOfCode(this.state.code)}>test</button>
        </div>
    }

    getUserOfCode(code){
        axios.post("/api/user/oauth/github",{
            code
        }).then(({data})=>{
            if(data.status === 200){
                localStorage.setItem("user",JSON.stringify(data.data))
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}