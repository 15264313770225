import axios from 'axios';
import { getCookie } from './component/tools';

const debug = false

const request = axios.create({
    // baseURL:"https://backgulf.com"
    baseURL:debug?"http://localhost:7002":""
})


// 调试 只使用 token交互
// 正式版只使用cookie

request.interceptors.request.use(
    request=>{
        if(debug){
            let token = localStorage.getItem("token");
            if(token){
                request.headers.common["Authentication"]=token;
            }
        }else{
            //正常情况下，没有cookie的情况下，应该清除 localStorage
            if(!getCookie("token")){
                localStorage.clear();
            }
        }
        //到这里token就只是调试用的了
        //这里其实就不需要设置 请求头了 因为cookie会自动带过去
        return request
    }
)

request.interceptors.response.use(
    response=>{
        return response;
    },
    error=>{
        if(error.response){
            switch (error.response.status) {
                case 401:
                    //未授权，cookie失效 清除cookie(由服务端设置cookie时间来控制)
                    //清除用户信息，清除token
                    localStorage.clear()
                    window.location='/'
                    break;
                case 403:
                    //禁止访问 只跳转回首页
                    window.location='/'
                    break;
                default:{
                }
            }
        }
        return Promise.reject(error);
    }
)




export {request as axios};
