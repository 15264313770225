import React from "react";
import { axios } from "../../axios.cfg";
import Confirm from "../my-components/comfirm";


export default class FlashManage extends React.Component{
    state={
        flashes : [],
        total:0
    }
    componentDidMount(){
        if(!localStorage.getItem("user")){
            window.location.href="/"
            return
        }
        this.getAll()
    }
    render(){
        return<div>
            <div>
                {
                    this.state.flashes.map(e=><p key={e.id}>
                        {e.content}

                        {
                            e.hidden?<span onClick={()=>this.hiddenOrNot(e.id)}>取消隐藏</span>:
                            <span onClick={()=>this.hiddenOrNot(e.id)}>隐藏</span>
                        }
                        <span onClick={()=>this.delete(e.id)}>删除</span>
                    </p>)
                }
            </div>
        </div>
    }

    hiddenOrNot(id){
        Confirm.confirm("确认隐藏/取消隐藏？",()=>{
            axios.put("/api/flash/hide",{
                id
            }).then(data=>{
                console.log(data)
            }).catch(err=>{
                console.log(err)
            })
        },()=>{})

    }
    delete(id){
        Confirm.confirm("确认删除？",()=>{
            axios.delete("/api/flash",{
                params:{
                    id
                }
            }).then(data=>{
                console.log(data)
            }).catch(err=>{
                console.log(err)
            })
        },()=>{})
    }

    getAll(){
        axios.get("/api/flash/list",{
            params:{
                page:0,
                pageSize:20
            }
        }).then(({data})=>{
            if(data.status === 200){
                this.setState({...this.state,flashes:data.data.data,total:data.data.total})
            }
            console.log(data)
        }).catch(err=>{
            console.log(err)
        })
    }
}