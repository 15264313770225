import React  from "react";
import { render, unmount } from 'rc-util/lib/React/render';


import style from "./index.module.css"
export default class Message extends React.Component{
    state={
        msg:"",
        type:1 //1成功 0失败 2warning
    }

    componentDidMount(){
        this.setState({...this.state,msg:this.props.msg,type:this.props.type})
    }

    render(){
        return(<div className={style.bg} >
            <p>
                <span className={this.state.type===1?style.successMsg:style.failMsg}>{this.state.msg}</span>
            </p>
        </div>)
    }

    static success(msg){
        let cNode = document.createElement("div")
        document.body.appendChild(cNode)
        render(<Message msg={msg} type={1} />,cNode)
        setTimeout(()=>{
            unmount(cNode)
        },1000)
    }

    static fail(msg){
        let cNode = document.createElement("div")
        document.body.appendChild(cNode)
        render(<Message msg={msg} type={0} />,cNode)
        setTimeout(()=>{
            unmount(cNode)
        },1000)
    }
}