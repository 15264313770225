import React from "react";
import { axios } from "../../axios.cfg";
import Message from "../my-components/message";


export default class FlashAddPage extends React.Component{
    state={
        content:''
    }

    componentDidMount(){
        if(!localStorage.getItem("user")){
            window.location.href="/"
            return
        }
    }
    render(){
        return<div>
            <textarea onChange={({target})=>this.setState({...this.state,content:target.value})}></textarea>
            <p>
                <span onClick={()=>this.add()}>提交</span>
            </p>
        </div>
    }

    add(){
        if(!this.state.content){
            Message.fail("请输入内容")
            return
        }
        axios.post("/api/flash",{
            content:this.state.content
        }).then(data=>{
            console.log(data)
        }).catch(err=>{
            console.log(err)
        })
        
    }
}