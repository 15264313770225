import { Link, useParams } from "react-router-dom"
import { axios } from "../../../axios.cfg"
import { useEffect,useState } from "react"

import style from './phone.folder.notes.module.css'
import { Button } from "antd"
import AddPage from "./add"

export default function PhoneNotesFolder(props){
    const param = useParams()
    let folderId = param.folder
    useEffect(()=>{
        getNotes()
        getFolder()
        // eslint-disable-next-line
    },[folderId])

    const [notes,setNotes] = useState([])
    const [folder,setFolder] = useState({})

    const [showAddPage,setShowAddPage] = useState(false)

    return <div className={style.container}>
        <h2>{folder.folder}</h2> 
        <div>
            {notes.map((e,index)=><div key={index}>
                <Link to={`/note/${folderId}/${e.id}`}>{e.title}</Link>
            </div>)}
        </div>
        <div>
            <p>
                <Button onClick={()=>setShowAddPage(true)}>新建</Button>
            </p>
        </div>

        <div className={showAddPage?'':style.addPageDispalay} >
            <AddPage close={(result)=>{
                setShowAddPage(false)
                if(result){
                    getNotes()
                }
            }} folder={folder.folder}/>
        </div>
    </div>


    function getNotes() {
        axios.get("/api/note/ofTagId",{
            params:{
                folderId
            }
        }).then(({data})=>{
            if(data.status === 200){
                setNotes(data.data)
            }
        }).catch(err=>{
        })
    }

    function getFolder() {
        axios.get("/api/note/folder",{
            params:{
                id:folderId
            }
        }).then(({data})=>{
            if(data.status === 200){
                setFolder(data.data)
            }
        }).catch(err=>{})
    }

}