import React from "react";
import { useParams } from "react-router-dom";
import { axios } from "../../axios.cfg";

import Select from "../my-components/select";
import Message from "../my-components/message";
import MyEditor from "../tools/editor";

import style from "./edit.module.css"
import { Button, Input, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";

class Fuck extends React.Component{

    componentDidMount(){
        let id = this.props.id
        this.getDetail(id)
        this.getTags()
    }

    state={
        article:{
            title:"",
            slug:"",
            content:"",
            tags:[],
            private:false
        },
        tags:[],
        shows:{
            tag:false,
            archive:false
        },
        archives:[],
        archive:{}
    }

    render(){
        return<div className={style.container}>
            <div>
                <Input placeholder="标题" allowClear value={this.state.article.title}
                    onChange={e=>this.setState({...this.state,article:{...this.state.article,title:e.target.value}})} />
                <TextArea placeholder="简述" autoSize value={this.state.article.slug} 
                    onChange={e=>this.setState({...this.state,article:{...this.state.article,slug:e.target.value}})} />
                
                {this.state.article.id&&<Switch checkedChildren="私有" unCheckedChildren="公开" 
                    defaultChecked={this.state.article.private}
                    onChange={(e)=>this.setState({...this.state,article:{...this.state.article,private:e}})} />}
                <div>
                    {
                        this.state.article.tags?this.state.article.tags.map(e=><Button key={e.id}>{e.tag}</Button>):""
                    }
                    <Button onClick={()=>this.setState({...this.state,shows:{...this.state.shows,tag:true}})}>选择标签</Button>

                    <Button onClick={()=>this.showArchive()}>选择合集</Button>
                    {this.state.archive.id&&<Button onClick={()=>this.setState({...this.state,archive:{}})}>{this.state.archive.archive}</Button>}
                    {this.state.shows.archive&&<div className={style.archives}>
                        <div>
                            {this.state.archives.map(e=><Button key={e.id}
                                onClick={()=>this.setState({...this.state,archive:e,shows:{}})}>{e.archive}</Button>)}
                            <p>
                                <Button onClick={()=>this.setState({...this.state,shows:{...this.state.shows,archive:false}})}>取消</Button>
                            </p>  
                        </div>
                    </div>}
                </div>

                {
                    this.state.shows.tag?<Select
                      items={this.state.tags.map(function(e){return {key:e.id,value:e.tag}})}
                      backData={this.backTags}
                      onClose={()=>this.setState({...this.state,shows:{...this.state.shows,tag:false}})} />:""
                }

                {
                    this.state.article.id&&
                    <MyEditor 
                    initData={this.state.article.content}
                    backData={this.backData}
                />
                }

                <div>
                    <Button onClick={this.submit}>提交</Button>
                </div>
            </div>
        </div>
    }

    getDetail = (id) => {
        axios.get("/api/article", {
            params: {
              id,
            },
           })
        .then(({ data }) => {
          if (data.status === 200) {
            let archive = data.data.archive?data.data.archive:{}
            console.log(archive)
            this.setState({article: data.data,archive });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      };
      
      getTags = ()=>{
        axios.get("/api/a/tag/tags")
          .then(({ data }) => {
            this.setState((state) => {
              return { ...state, tags: data };
            });
          }).catch((err) => {
            console.log(err);
          });
      };

    backData=(e)=>{
        this.setState({...this.state,article:{...this.state.article,content:e},
            shows:{...this.state.shows,tag:false}})
    }

    backTags=(e)=>{
        this.setState({...this.state,article:{...this.state.article,tags:e.map(function(tem){
            return {id:tem.key,tag:tem.value}
        })},shows:{...this.state.shows,tag:false}})
    }


    submit=()=>{
        if(!this.state.article.title){
           Message.fail("请输入标题");
           return
        }
        if(!this.state.article.content){
            Message.fail("请输入文章内容");
            return
        }
        if(!this.state.article.tags.length === 0){
            Message.fail("请先选择标签");
            return
        }

        axios.put("/api/article",{
            ...this.state.article,
            tags:this.state.article.tags.map(function(e){return e.id}),
            archiveId:this.state.archive.id
        }).then(({data})=>{
            Message.success("修改成功")
        }).catch(err=>{
            console.log(err)
        })
    }

    showArchive=()=>{
        axios.get("/api/a/archive/all",{
            params:{
                page:0,
            }
        }).then(({data})=>{
            console.log(data)
            if(data.status === 200){
                this.setState({...this.state,archives:data.data,shows:{...this.state.shows,archive:true}})
            }
        })
    }

}

function ArticleEdit(){
    return <Fuck id={useParams().id}/>
}

export default ArticleEdit;