import React from "react";
import { Link } from "react-router-dom";
import { axios } from "../../../axios.cfg";
import Confirm from "../../my-components/comfirm";
import Message from "../../my-components/message"
import Pagination from "../../my-components/pagination";

import style from './root.article.list.module.css'

import { Button, Select, Table } from "antd";

export default class RootArticleList extends React.Component{

    state={
        articles:[],
        total:0,
        pageSize:12,
        currentPage:1,
        tags:[],
        filter:{
            tag:'',
            isPrivate:'',
            search:'',
            deleted:''
        }
    }
    componentDidMount(){
        this.getArticles()
        this.getTags()
    }
    render(){
        return<div className={style.container}>

            <div className={style.filter}>
                <Select style={{width:"8em"}} placeholder='筛选标签' options={this.state.tags.map(e=>{
                    return {
                        value:e.id,
                        label:e.tag
                    }
                })} onChange={value=>{
                    this.setState({...this.state,filter:{...this.state.filter,tag:value}})
                }} allowClear/>
                <Select style={{width:"8em"}} placeholder='是否公开' options={[
                    {
                        value:'private',
                        label:'Private'
                    },
                    {
                        value:'public',
                        label:'Public'
                    }
                ]} onChange={e=>{
                    if(!e){
                        this.setState({...this.state,filter:{...this.state.filter,isPrivate:''}})
                    }else if(e==='private'){
                        this.setState({...this.state,filter:{...this.state.filter,isPrivate:true}}) 
                    }else if(e==='public'){
                        this.setState({...this.state,filter:{...this.state.filter,isPrivate:false}})
                    }
                }} allowClear/>
                <Select style={{width:"8em"}} placeholder='是否删除' options={[
                    {
                        value:'deleted',
                        label:'已删除'
                    },
                    {
                        value:'undeleted',
                        label:'未删除'
                    }
                ]} onChange={e=>{
                    if(!e){
                        this.setState({...this.state,filter:{...this.state.filter,deleted:''}})
                    }else if(e==='deleted'){
                        this.setState({...this.state,filter:{...this.state.filter,deleted:true}}) 
                    }else if(e==='undeleted'){
                        this.setState({...this.state,filter:{...this.state.filter,deleted:false}})
                    }
                }} allowClear/>
                
                <Button onClick={()=>this.setState({...this.state,currentPage:1},()=>this.getArticles())}>搜索</Button>
            </div>

            <div>
                <Table columns={[
                    {title:'Id',dataIndex:'id',width:"1em",render:(_,row)=>{
                        return <a href={'/a/detail/'+row.id}>{row.id}</a>}
                    },
                    {title:'状态',dataIndex:'status',width:"6em",render:(_,row)=>{
                        return <span>{row.status===0?'正常':'已删除'}</span>}
                    },
                    {title:'状态2',dataIndex:'private',width:"5em",render:(_,row)=>{
                        return <span>{row.private?'私有':'公开'}</span>}
                    },
                    {title:'Tags',dataIndex:'id',width:"5em",render:(_,row)=>{
                        return <>
                            {row.tags.map(e=><span key={e.id} style={{
                                border:"1px solid green",
                                fontSize:"0.6em",
                                padding:"2px",
                                margin:"2px",
                                borderRadius:"2px"
                            }}>{e.tag}</span>)}
                        </>
                    }},
                    {
                        title:'合集',dataIndex:'archive',width:"10em",render:(_,row)=>{
                            return <span>{row.archive?row.archive.archive:"无"}</span>
                        }
                    },
                    {title:'CreateDatetime',dataIndex:'createDatetime',width:'18em'},
                    {title:'Title',dataIndex:'title'},
                    {title:'操作',dataIndex:'id',width:'10em',render:(_,row)=>{
                        return <>
                            <Link to={`/root/a/edit/${row.id}`}>编辑</Link>
                            <span onClick={()=>this.privateOrPublic(row)}>{row.private?'公开化':'私有化'}</span>
                            <span onClick={()=>this.logicDelete(row)}>删除</span>
                        </>}
                    }
                ]} dataSource={this.state.articles} rowKey={'id'} pagination={false}/>
            </div>

            <div>
                <Pagination total={this.state.total} onChange={this.onChange} currentPage={this.state.currentPage} />
            </div>
        </div>
    }



    getArticles=()=>{
        axios.get("/api/article/admin",{
            params:{
                page:this.state.currentPage-1,
                pageSize:10,
                ...this.state.filter
            }
        }).then(({data})=>{
            this.setState({...this.state,articles:data.data.data,total:data.data.total})
        }).catch(err=>{
            console.log(err)
        })
    }
    onChange=(index)=>{
        this.setState({...this.state,currentPage:index},()=>{
            this.getArticles()
        })
    }

    privateOrPublic=(e)=>{
        Confirm.confirm(e.private?"公开它？":"确认置为私有?",()=>{
            axios.put("/api/article/pri-pub",{
                id:e.id,
                private:!e.private
            }).then(({data})=>{
                if(data.status===200){
                    Message.success("修改成功")
                    this.getArticles()
                }
            }).catch(err=>{
                console.log(err)
            })
        },()=>{})
    }

    logicDelete=(e)=>{
        Confirm.confirm("确定删除？",()=>{
            axios.delete("/api/article",{
                params:{
                    id:e.id
                }
            }).then(({data})=>{
                if(data.isok){
                    Message.success("删除成功")
                    this.getArticles()
                }
            }).catch(err=>{
                console.log(err)
            })
        },()=>{})
    }


    getTags = ()=>{
        axios.get("/api/a/tag/tags")
          .then(({ data }) => {
            this.setState((state) => {
              return { ...state, tags: data };
            });
          }).catch((err) => {
            console.log(err);
          });
      };

}