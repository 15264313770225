import { Button, Input } from "antd";

import style from "./add.module.css"
import MyEditor from "../../tools/editor";
import { useState } from "react";
import { axios } from "../../../axios.cfg";

export default function AddPage(props){

    const [noteDetail,setNoteDetail] = useState({})

    return<div className={style.container}>

        <div>
            <h2>{props.folder}</h2>
        </div>

        <div className={style.editor}>
            <Input value={noteDetail.title} onChange={(e)=>setNoteDetail({...noteDetail,title:e.target.value})} />
            {noteDetail.id?
            <MyEditor initData={noteDetail.content} backData={(e)=>setNoteDetail({...noteDetail,content:e})} />:
            <MyEditor backData={(e)=>setNoteDetail({...noteDetail,content:e})} />}
        </div>
        <p>
            <Button onClick={()=>props.close()}>取消</Button>
            <Button onClick={submitAdd}>提交</Button>
        </p>
    </div>


    function submitAdd() {
        axios.post("/api/note",{
            title:noteDetail.title,
            content:noteDetail.content,
            folder:props.folder
        }).then(({data})=>{
            if(data.status === 200){
                alert("添加成功")
                props.close(true)
            }
        }
        ).catch(err=>{})
    }
}