import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

const editorConfig={
	toolbar: {
		items: [
			'heading',
			'bold',
			'italic',
			'fontColor',
			'highlight',
			'link',
			'codeBlock',
			'code',
			'|',
			'undo',
			'redo'
		]
	},
	language: 'en'
}

export default function MyEditor(props){
    return<>
        <CKEditor
            editor={ Editor }
            config = { editorConfig }
            data={props.initData?props.initData:"请输入..."}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                backData(data)
            } }
        />
    </>

    function backData(content){
        if(props.backData){
            props.backData(content)
        }
    }
}
