import React from "react"
import style from "./folder.list.module.css"
import {axios} from "../../axios.cfg"

import Message from "../my-components/message"
import Confirm from "../my-components/comfirm";


export default class FolderList extends React.Component{
    state={
        folders:[],
        folderOpenState:false,
        activeFolder:'',
        showAddFolder:false,
        folterToAdd:""
    }
    componentDidMount(){
        this.getFolders()
    }

    render(){
        return(
            <div className={style.container} >
                <div>
                    <p><span className={[style.btn].join()} onClick={this.createNote}>新建</span></p>
                    <p className={this.state.activeFolder==='unnamed'?`${style.active} ${style.btn}`:style.btn} onClick={()=>this.activeFolder('unnamed')}>未分组</p>
                    {
                        this.state.showAddFolder?<div className={style.addTagContainer}>
                            <p><span onClick={()=>this.setState({...this.state,showAddFolder:false})}>x</span></p>
                            <input placeholder="标签" onChange={(e)=>this.setState({...this.state,folterToAdd:e.target.value})} />
                            <span onClick={this.addFolder}>提交</span>
                        </div>:""
                    }
                        

                    <div>
                        <p className={style.foldersClicker}>
                         <span className={style.btn} onClick={this.showFolders}>文件夹</span>
                         <span className={style.btn} onClick={()=>this.setState({...this.state,showAddFolder:true})}>add</span>
                        </p>

                        <div className={style.tags}>
                            {this.state.folderOpenState&&this.state.folders.map((e,index)=>
                                <div key={index}>
                                    <span className={this.state.activeFolder===e?`${style.active} ${style.btn}`:style.btn} onClick={()=>this.activeFolder(e)}>{e}</span>
                                    <div  className={style.menu}>
                                        <span>...</span>
                                        <span className={style.menuContainer}>
                                            <span>新建</span>
                                            <span onClick={()=>this.delFolder(e)}>删除</span>
                                            <span>重命名</span>
                                        </span>
                                    </div>
                                    
                                </div>
                            )}
                        </div>
                    </div>
                    <p className={this.state.activeFolder==='deleted'?`${style.active} ${style.btn}`:style.btn} onClick={()=>this.activeFolder('deleted')}>回收站</p>
                </div>
                
            </div>
        )
    }


    getFolders=()=>{
        const that = this;
        axios.get("/api/note/allfolder").then(({data})=>{
            if(data.isok&&data.data.length>0){
                that.setState({...that.state,folders:data.data});
            }
        })
    }

    showFolders=()=>{
        this.setState({...this.state,folderOpenState:!this.state.folderOpenState});  
    }

    activeFolder=(folder)=>{
        this.setState({...this.state,activeFolder:folder});
        this.props.getNotesOfFolder(folder);
    }

    delFolder=(folder)=>{
        Confirm.confirm("确定删除?",()=>{
            axios.delete("/api/note/folder",{
                params:{
                    folder
                }
            }).then(({data})=>{
                console.log(data)
                if (data.status === 200){
                    //刷新tag列表
                    this.getFolders()
                }
            }).catch(err=>{
                console.log(err)
            })
        },()=>{

        })
    }


    // 添加目录
    addFolder=()=>{
      axios.post("/api/note/folder",{
        folder:this.state.folterToAdd
      }).then(({data})=>{
        if(data.status === 200){
            Message.success("添加成功")
            this.getFolders()
        }else if (data.status !== 200){
            Message.fail("重复")
        }
      }).catch(err=>{
        console.log(err)
      })
    }

    createNote=()=>{
        this.props.createNote(this.state.activeFolder)
    }
}
















