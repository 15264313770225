import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

const editorConfig={
    toolbar: {
		items: [
			'heading',
			'bold',
			'italic',
			'fontColor',
			'highlight',
			'link',
			'codeBlock',
			'code',
			'|',
			'undo',
			'redo'
		]
	},
	language: 'en'
}

export default class FlashEditor extends React.Component{
    render(){
        return <CKEditor
            editor={ Editor }
            config = { editorConfig }
            onReady={ editor => {
                editor.setData(this.props.initData?this.props.initData:"请输入...");
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                if(this.props.backData){
                    this.props.backData(data)
                }
            } }
        />
    }
}