import React from "react";
import { Route, Routes } from "react-router-dom";
import FlashManage from "../flash/flash.manage";
import RootArticles from "./articles";
import RootHeader from "./header";
import RootQA from "./qas";


export default class Root extends React.Component{

    render(){
        return <div>
            <RootHeader />

            <Routes>
                <Route path="a/*" element={<RootArticles />} />
                <Route path="qa/*" element={<RootQA />} />
                <Route path="flash" element={<FlashManage />} />
            </Routes>
        </div>
    }
}