import React from "react";
import { axios } from "../../../axios.cfg";
import Pagination from "../../my-components/pagination";
import { Button, Table } from "antd";


export default class RootArticlesDeleted extends React.Component{

    state={
        articles:[],
        total:0,
        pageSize:12,
        currentPage:1
    }
    componentDidMount(){
        this.getDeleted()
    }
    
    render(){
        return <div>

            <div>
                <Table columns={
                    [
                    {title:'Id',dataIndex:'id',width:"1em",render:(_,row)=>{
                        return <a href={'/a/detail/'+row.id}>{row.id}</a>}
                    },
                    {title:'状态',dataIndex:'status',width:"6em",render:(_,row)=>{
                        return <span>{row.status===0?'正常':'已删除'}</span>}
                    },
                    {title:'状态2',dataIndex:'private',width:"5em",render:(_,row)=>{
                        return <span>{row.private?'私有':'公开'}</span>}
                    },
                    {title:'Tags',dataIndex:'id',width:"5em",render:(_,row)=>{
                        return <>
                            {row.tags.map(e=><span key={e.id} style={{
                                border:"1px solid green",
                                fontSize:"0.6em",
                                padding:"2px",
                                margin:"2px",
                                borderRadius:"2px"
                            }}>{e.tag}</span>)}
                        </>
                    }},
                    {
                        title:'合集',dataIndex:'archive',width:"10em",render:(_,row)=>{
                            return <span>{row.archive?row.archive.archive:"无"}</span>
                        }
                    },
                    {title:'CreateDatetime',dataIndex:'createDatetime',width:'18em'},
                    {title:'Title',dataIndex:'title'},
                    {title:'操作',dataIndex:'id',width:'10em',render:(_,row)=>{
                        return <>
                            <Button onClick={()=>this.recovery(row.id)}>恢复</Button>
                            <Button onClick={()=>{this.realDelete(row.id)}}>彻底删除</Button>
                        </>}
                    }
                    ]
                } dataSource={this.state.articles} rowKey={'id'} pagination={false} />
            </div>
            <Pagination total={this.state.total} 
                    pageSize={this.state.pageSize}
                    onChange={this.onChange} 
                    currentPage={this.state.currentPage} />
        </div>
    }


    getDeleted=()=>{
        axios.get("/api/article/deleted",{
            params:{
                page:this.state.currentPage-1,
                pageSize:this.state.pageSize
            }
        }).then(({data})=>{
            console.log(data)
            this.setState({...this.state,articles:data.data.articles,total:data.data.total})
        }).catch(err=>{
            console.log(err)
        })
    }
    onChange=(index)=>{
        this.setState({...this.state,currentPage:index},()=>{
            this.getDeleted()
        })
    }


    recovery=(id)=>{
        axios.put("/api/article/restore",{
            id:id
        }).then(({data})=>{
            if(data.status===200){
                this.getDeleted()
            }
        }).catch(err=>console.log(err))
    }

    realDelete=(e)=>{
        axios.delete("/api/article/delete",{
            params:{
                id:e
            }
        }).then(({data})=>{
            if(data.status===200){
                this.getDeleted()
            }
        })
    }

}