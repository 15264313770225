import React from 'react';
import {Routes,Route,BrowserRouter,Link} from 'react-router-dom';
import Answers from './answers';
import Asks from './asks';
import Activities from './activities';
import Header from '../header';
import style from './style/index.module.css'
import UserHeader from './user.header';

import {getLoggedUser} from "../tools/index"

export default class User extends React.Component{
    componentDidMount(){
        let username = getLoggedUser();
        if(username){
            this.setState({username:this.props.match.params.username,loggedUser:username})
        }else{
            this.setState({username:this.props.match.params.username});
        }
    }
    state={
        username:"",
        loggedUser:"",
        editProfileState:false
    }
    render(){
        return(
            <div className={style.main}>
                <Header />
                <UserHeader />
                <div className={style.left}>
                    <BrowserRouter>
                    <div className={style.navibar}>
                        <span><Link to={"/user/"+this.state.username}>动态</Link></span>
                        <span><Link to={"/user/"+this.state.username+"/answers"}>回答</Link></span>
                        <span><Link to={"/user/"+this.state.username+"/asks"}>提问</Link></span>
                        <span><Link to={"/user/"+this.state.username+"/post"}>文章</Link></span>
                    </div>
                        <Routes>
                                <Route exact path="/user/:username" component={Activities} />
                                <Route exact path="/user/:username/answers" component={Answers}/>
                                <Route exact path="/user/:username/asks" component={Asks}/>
                        </Routes>
                    </BrowserRouter>
                </div>

                <div>
                    right
                </div>
            </div>
        )
    }


}


