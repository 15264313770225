import React from "react"
import style from "./style/user.header.module.css"

export default class UserHeader extends React.Component{

    state={

    }


    render(){
        return <div>
            <div className={style.container}>
                <div>
                    background
                </div>
                <div>
                    <div>
                        left profile
                    </div>
                    <div>
                        <p>header</p>
                        <p>signature</p>
                    </div>
                    <div>
                        <span>关注</span>
                        <span>私信</span>
                        <span>修改资料</span>
                    </div>
                </div>
            </div>
        </div>
    }
}