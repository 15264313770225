import { Route, Routes } from "react-router-dom";
import PhoneNotesFolders from "./phone.folders";
import PhoneNotesNote from "./phone.note";
import PhoneNotesFolder from "./phone.folder.notes";



export default function PhoneNotes(pros){


    return<div>
        
        <Routes>
            <Route path="/" element={<PhoneNotesFolders/>}/>
            <Route path="/:folder" element={<PhoneNotesFolder/>}/>
            <Route path="/:folder/:note" element={<PhoneNotesNote/>}/>
        </Routes>
    </div>
}