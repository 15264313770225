import DOMPurify from "dompurify";
import React from "react";
import { axios } from "../../axios.cfg";
import Header from "../header";
import Confirm from "../my-components/comfirm";
import Message from "../my-components/message";
import Pagination from "../my-components/pagination";
import { getLoggedUser } from "../tools";
import FlashEditor from "./editor";

import style from "./index.module.css"

export default class Flash extends React.Component{

    state={
        flashes:[],
        total:0,
        currentPage:1,
        pageSize:16,
        search:"",
        add:{
            showEdit:false,
            flash:""
        },
        tags:[],
        currentTag:"最新"
    }
    
    constructor(props){
        super(props);
        let user = getLoggedUser();
        if(user){
            this.state=({...this.state,user})
        }
    }
    componentDidMount(){
        this.getFlashs();
        this.getHostTags();
    }


    render(){
        return<div>
            <Header path={this.props.path} />

            <div className={style.body}>
                <div className={style.left}>
                    <div className={style.leftHeader}>
                        {/* <div className={this.state.currentTag==='#最新'?style.currentTag:''}
                            onClick={()=>this.getAllByTag('#最新')}
                        >最新</div>
                        <div className={this.state.currentTag==='#收藏'?style.currentTag:''} 
                            onClick={()=>this.getAllByTag('#收藏')}
                        >收藏</div>
                        <div className={this.state.currentTag==='#记录'?style.currentTag:''}
                            onClick={()=>this.getAllByTag('#记录')}
                        >记录</div>
                        <div className={this.state.currentTag==='#美好'?style.currentTag:''}
                            onClick={()=>this.getAllByTag('#美好')}
                        >美好</div>
                        <div className={this.state.currentTag==='#涨知识'?style.currentTag:''}
                            onClick={()=>this.getAllByTag('#涨知识')}
                        >涨知识</div>
                        <div className={style.search}>
                            <input placeholder="🔍" />
                        </div> */}
                        {
                            this.state.user?<div className={style.addBtn}
                                onClick={()=>this.setState({...this.state,
                                    add:{...this.state.add,showEdit:!this.state.add.showEdit}})}
                            ><span>➕</span></div>:""
                        }
                    </div>

                    {this.state.add.showEdit?<div className={style.editor}>
                        <FlashEditor backData={this.backData}/>
                        <div>
                            <button onClick={this.submitAdd}>提交</button>
                        </div>
                    </div>:""}

                    <div className={style.cards}>
                        {this.state.flashes.map((e)=>{
                                return <div key={e.id}
                                className={style.card}
                                >
                                <div className={style.flashContent} dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(e.content)}}></div>
                                
                                {this.state.user?<p>
                                    <span>编辑</span>
                                    <span onClick={()=>this.deleteFlash(e.id)}>删除</span>
                                    {
                                        e.state===0?<span onClick={()=>this.hideOrExpose(e.id)}>仅我可见</span>:
                                        e.state===1?<span onClick={()=>this.hideOrExpose(e.id)}>设为公开</span>:""
                                    }
                                </p>:""}
                                </div>
                        })}
                    </div>

                    <div>
                        <Pagination total={this.state.total} currentPage={this.state.currentPage} pageSize={this.state.pageSize} 
                            onChange={(e)=>{
                                this.setState({...this.state,currentPage:e},()=>{
                                    this.getFlashs()
                                })
                            }}
                        />
                    </div>
                </div>

                <div className={style.right}>
                    <div>
                        <p>标签列表</p>
                        <div className={style.tags}>
                            {this.state.tags.map(e=><span key={e.id} 
                                onClick={()=>this.getAllByTag(e.tag)}
                            >{e.tag}</span>)}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }


    backData=(content)=>{
        this.setState({...this.state,add:{...this.state.add,flash:content}})
    }

    getFlashs=()=>{
        axios.get("/api/flash",{
            params:{
                page:this.state.currentPage-1,
                pageSize:this.state.pageSize
            }
        }).then(({data})=>{
            if(data.status === 200){
                data = data.data
                this.setState({...this.state,flashes:data.data,total:data.total})
            }else{
                Message.fail("fuck")
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    submitAdd=()=>{
        let flash = this.state.add.flash
        if(!flash){
            alert("fuck")
            return
        }
        axios.post("/api/flash",{
            content:flash
        }).then(({data})=>{
            if(data.status===200){
                this.getFlashs()
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    getHostTags=()=>{
        axios.get("/api/flash/tag/hot").then(({data})=>{
            if(data.status===200){
                this.setState({...this.state,tags:data.data})
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    deleteFlash=(id)=>{
        Confirm.confirm("确认删除？",()=>{
            axios.delete("/api/flash",{
                params:{
                    id
                }
            }).then(({data})=>{
                if(data.status===200){
                    this.getFlashs()
                }
            }).catch(err=>{
                console.log(err)
            })
        })

    }

    hideOrExpose=(id)=>{
        axios.put("/api/flash/hide",{
            id
        }).then(({data})=>{
            if(data.status===200){
                this.getFlashs()
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    //根据标签获取
    getAllByTag=(tag)=>{
        this.setState({...this.state,currentTag:tag},()=>{
            axios.get("/api/flash",{
                params:{
                    tag,
                    page:this.state.currentPage-1,
                    pageSize:this.state.pageSize
                }
            }).then(({data})=>{
                if(data.status === 200){
                    data = data.data
                    this.setState({...this.state,flashes:data.data,total:data.total})
                }else{
                    Message.fail("fuck")
                }
            }).catch(err=>{
                console.log(err)
            })
        })

    }
}

