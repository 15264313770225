
import React from 'react';

export default class Activities extends React.Component{

    render(){
        return(
            <p>activities</p>
        )
    }
}