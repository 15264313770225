import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import ArticleAdd from "../../article/add";
import RootArticlesDeleted from "./root.article.deleted";
import RootArticleList from "./root.article.list";
import RootArticleTags from "./root.article.tags";

import style from "./css/index.module.css"
import ArticleEdit from "../../article/edit";
import ArticleArchives from "./article.archive";


export default class RootArticles extends React.Component{
    state={
        index: ''
    }

    render(){
        return<div>
            <div className={style.header}>
                <Link to={'/root/a'} className={this.state.index===''?style.active:''}
                    onClick={()=>this.setState({...this.state,index:''})}>统计</Link>
                <Link to={'/root/a/list'} className={this.state.index==='list'?style.active:''}
                    onClick={()=>this.setState({...this.state,index:'list'})}>文章列表</Link>
                <Link to={'/root/a/tags'} className={this.state.index==='tags'?style.active:''}
                    onClick={()=>this.setState({...this.state,index:'tags'})}>文章标签</Link>
                <Link to={'/root/a/deleted'} className={this.state.index==='deleted'?style.active:''}
                    onClick={()=>this.setState({...this.state,index:'deleted'})}>删除列表</Link>
                <Link to={'/root/a/add'} className={this.state.index==='add'?style.active:''}
                    onClick={()=>this.setState({...this.state,index:'add'})}>添加文章</Link>
                <Link to={'/root/a/archive'} className={this.state.index==='archive'?style.active:''}
                    onClick={()=>this.setState({...this.state,index:'archive'})}>合集</Link>
            </div>

            <Routes>
                <Route path="" element={<div>统计信息</div>} />
                <Route path="list" element={<RootArticleList />} />
                <Route path="tags" element={<RootArticleTags />} />
                <Route path="archive" element={<ArticleArchives />} />
                <Route path="deleted" element={<RootArticlesDeleted />} />
                <Route path="add" element={<ArticleAdd />} />
                <Route path="edit/:id" element={<ArticleEdit/>} />
            </Routes>

        </div>
    }

    fuck=()=>{
        console.log("fuck")
    }
}