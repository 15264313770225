import React from "react"
import style from "./index.module.css"
import baseStyle from "../../tools/base.module.css"
import { Button } from "antd"

export default class Select extends React.Component{

    state={
        multi:false,
        items:[{
            value:'',
            key:''
        }],
        choised:[]
    }

    componentDidMount(){
        let items = this.props.items?this.props.items:[]
        let choised = this.props.choised?this.props.choised:[]
        this.setState({...this.state,items,choised})
    }

    render(){
        return <React.Fragment>
            <p className={style.btn}>{this.props.placeholder}</p>
            {
            <div className={style.bg}>
                <div className={style.container}>
                        <input placeholder="搜索" />
                        <div>
                            <p className={style.tags}>
                            {
                                this.state.items.map(item=><Button key={item.key}
                                    onClick={()=>this.chose(item)}
                                    className={this.state.choised.some((a)=>a.key===item.key)?style.fuck:""}
                                    >{item.value}</Button>)
                            }
                            </p>
                            
                        </div>

                        <div>
                            <Button type="primary" className={baseStyle.btn} onClick={()=>this.backData()}>OK</Button> 
                            <Button type="primary" onClick={()=>this.props.onClose()} className={baseStyle.btn}>Cancel</Button>
                        </div>
                </div>
            </div>
            }
        </React.Fragment>
    }

    //add or remove the item clicked
    chose(item){
        let len = this.state.choised.length
        let have = false
        for(let i=0;i<len;i++){
            if(item.key === this.state.choised[i].key){
                have = true
                let a  = this.state.choised.slice(0,i).concat(this.state.choised.slice(i+1))
                this.setState({...this.state,choised:a})
                break
            }
        }
        
        if(!have){
            let a = this.state.choised
            a.push(item)
            this.setState({...this.state,choised:a})
        }

    }

    backData(){
        if(this.props.backData){
            this.props.backData(this.state.choised)
        }
    }

}