import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Notes from "../component/notes";
import User from "../component/user";
import Chat from "../component/chat";
import RegisterPage from "../component/user/register";
import Root from "../component/root";
import LoginPage from "../component/login";
import GithubOAuth from "../component/login/github";
import Flash from "../component/flash";
import FlashAddPage from "../component/flash/flash.add";
import FlashManage from "../component/flash/flash.manage";
import Home from "../component/home";
import Test from "../component/test";
import Setting from "../component/setting";
import PhoneNotes from "../component/notes/phone";

function MainRouter(props){

    const loggedin = localStorage.getItem("user")!=null
    //判断屏幕是否是手机
    const isMobile = document.documentElement.clientWidth<800

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home path="/" />} /> 
                <Route path="/root/*"  element={auth(loggedin,<Root />)} />
                <Route path="/user/:username" element={<User />}/>
                <Route path="/user/:username/*" element={<User />}/>

                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/oauth/github/*" element={<GithubOAuth />} />

                <Route path="/note/*" element={auth(loggedin,isMobile?<PhoneNotes />:<Notes />)} />
                <Route path="/chat"  element={<Chat />} />
                <Route path="/flash" element={<Flash path="/flash" />} />
                <Route path="/flash/add" element={<FlashAddPage />} />
                <Route path="/flash/manage" element={<FlashManage />} />

                <Route path="/test" element={<Test />} />
                <Route path="/setting" element={auth(loggedin,<Setting />)} />
            </Routes>

        </BrowserRouter>
    )


    //这里的auth函数是用来判断是否需要登录的 当需要登录却没有登录的时候，就跳转到首页
    function auth(need,Ele){
        if(need) return Ele
        return <Navigate to={'/'} />
        
    }
}


export default MainRouter;