import React from 'react';

export default class Answers extends React.Component{
    
    render(){

        return(
            <div>
            <p>answers</p>
                <p>用户名 回答时间</p>
                <p>ask标题</p>
                <p>答案</p>
                <p>操作 支持 点踩 添加收藏 设置：删除，仅自己可见，允许评论</p>
            </div>
        )
    }
}