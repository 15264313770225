import React from "react";
import { Link } from "react-router-dom";
import style from './index.module.css'

export default class RootHeader extends React.Component{
    
    render(){
        return<div>
            <Link to={'/root/a'} className={style.a}>文章管理</Link>
            <Link to={'/root/qa'} className={style.a}>QA管理</Link>
            <Link to={'/root/flash'} className={style.a}>Flash管理</Link>
        </div>
    }
}