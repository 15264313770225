import React from 'react';
import style from "./header.module.css";
import { Link } from 'react-router-dom';
import { axios } from '../axios.cfg';

import {checkOrDoNothing} from "./tools"


export default class Header extends React.Component{
    componentDidMount(){
        let user = checkOrDoNothing();
        if(user){
            this.setState({username:user.username})
        }
    }
    state={

    }
    render(){
        return(
            <header className={style.header} id="header">
                <div>
                    <div className={style.headerIcon}>
                        <a href="/">Back·Gulf</a>
                    </div>
                    <div className={style.headerItem}>
                        <a href="/" className={this.props.path==='/'?style.active:""}>Home</a>
                        <a href="/a">Articles</a>
                        <a href="/links">Links</a>
                    </div>
                    <div className={style.headerRight}>
                        <div>
                            {!this.state.username?(
                            <span><a href="/login">未登录</a></span>
                            ):(
                                <React.Fragment>
                                    <span>{this.state.username}</span>
                                    <ul className={style.userOperations}>
                                        <li><Link to={"/user/"+this.state.username}>个人主页</Link></li>
                                        <li><Link to="/note">笔记本</Link></li>
                                        <li><a href={"/root/a/add"}>添加文章</a></li>
                                        <li><a href="/">新建问题</a></li>
                                        <li>设置</li>
                                        <li onClick={this.clearCache}>清除缓存</li>
                                        <li><span className="fuck" onClick={this.logout}>注销</span></li>
                                    </ul>
                                </React.Fragment>
                            )   
                            }
                        </div>
                    </div>
                </div>
            </header>
        )
    }

    logout(){
        axios.get("/logout").then(({data})=>{
            localStorage.clear();
            window.location.reload();
        }).catch(err=>{
            console.log(err)
        })
    }

    clearCache(){
        caches.open("backgulf.cache").then(cache=>{
            cache.matchAll().then(e=>{
                e.forEach(item=>{
                    cache.delete(item.url)
                })
            })
        })
    }
}

