import React from 'react';

export default class Asks extends React.Component{
    
    render(){

        return(
            <p>Asks</p>
        )
    }
}