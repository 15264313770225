import React from "react"
import style from "./index.module.css"

export default class Pagination extends React.Component{

    state={
        currentPage:0,
        totalPage:0,
        pageSize:20,
        total:0,

        index:[],
        sysPageSet:5//导航可有几个按钮
    }

    componentDidMount(){
        let pageSize = 20
        let currentPage = 1
        if(this.props.pageSize){
            pageSize = this.props.pageSize
        }
        if(this.props.currentPage){
            currentPage = this.props.currentPage
        }
        this.setState({...this.state,pageSize,currentPage,totalPage:Math.ceil(this.props.total/pageSize),total:this.props.total},()=>{
            this.setPage(this.props.total,pageSize,currentPage,this.state.sysPageSet)
        })
    }

    componentDidUpdate(){
        if(this.props.total!== this.state.total){
            this.setState({...this.state,total:this.props.total},()=>this.setPage(
                this.state.total,this.state.pageSize,this.state.currentPage,this.state.sysPageSet
            ))
        }
    }

    setPage(total,pageSize,currentPage,sysPageSet){
        let totalPage = Math.ceil(total/pageSize)
        let index = []

        if(currentPage<=3){
            for(let i=1;i<=totalPage&&i<=sysPageSet;i++){
                index.push(i)
            }
        }else{
            if(totalPage<=sysPageSet){
                for(let i=1;i<=totalPage&&i<=5;i++){
                    index.push(i)
                }
            }else{
                let middle = Math.ceil(totalPage/2)
                if(currentPage<=middle){
                    index.push(currentPage-2,currentPage-1,currentPage,currentPage+1,currentPage+2)
                }else{
                    if(currentPage<=totalPage-2){
                        index.push(currentPage-2,currentPage-1,currentPage,currentPage+1,currentPage+2)
                    }else{
                        index.push(totalPage-4,totalPage-3,totalPage-2,totalPage-1,totalPage)
                    }
                }
            }
        }
        this.setState({...this.state,index,currentPage})
    }

    render(){
        return <div className={style.container}>
            <p>
                {
                    this.state.index.map(index=>
                    <span key={index}
                        onClick={()=>this.click(index)}
                        className={[style.btn,this.props.currentPage===index?style.current:""].join(" ")}
                    >{index} </span>
                    )
                }
            </p>
        </div>
    }

    click=(index)=>{
        this.setPage(this.props.total,this.state.pageSize,index,this.state.sysPageSet)

        //返回当前页
        //处理响应
        this.props.onChange(index)
    }
}

