
import React from "react";

export default class Test extends React.Component{

    state={
        a:'',
        b:''
    }

    render(){
        return <div>
            <p>asdasd</p>
            
            <button onClick={this.seta}>AAAA</button>
            <button onClick={this.setb}>BBBB</button>
            <button onClick={this.setb2}>CCCC</button>
        </div>
    }

    seta=()=>{
        this.setState({a:"aaaaa"})
    }
    setb=()=>{
        this.setState({b:"bbbb"})
    }
    setb2=()=>{
        this.setState({b:"bbbbbbbb",a:"b2"})
    }
}