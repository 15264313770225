import React from "react";
import { Routes,Route, Link } from "react-router-dom";



export default class RootQA extends React.Component{
    render(){
        return<div>

            <div>
                <Link to={'/root/qa'}>统计信息</Link>
                <Link to={'/root/qa/list'}>QA列表</Link>
                <Link to='/root/qa/tags'>QA Tags</Link>
            </div>
            <Routes>
                <Route path="" element={<div>统计信息</div>} />
                <Route path="list" element={<div>list</div>} />
                <Route path="tags" element={<div>tags</div>} />
            </Routes>
        </div>
    }
}