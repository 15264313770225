import React from "react";
import style from "./index.module.css"
import baseStyle from "../btn/index.module.css"
import { render, unmount } from 'rc-util/lib/React/render';

export default class Confirm extends React.Component{
    state={
        text:""
    }
    constructor(props){
        super(props)
        this.container = props.container
    }
    componentDidMount(){
        this.setState({node:this.props.root,text:this.props.text})
    }
    render(){
        return <div className={style.bg}>
            <div className={style.container}>
                <div className={style.text}>
                    <p>{this.state.text}</p>
                </div>
                
                <div className={style.btns}>
                    <span onClick={this.ok} className={baseStyle.btn}>OK</span>
                    <span onClick={this.no} className={baseStyle.btn}>NO</span>
                </div>
            </div>
        </div>
    }

    ok=()=>{
        if(this.props.ok){
            this.props.ok()
        }
        unmount(this.container)
    }
    no=()=>{
        if(this.props.no){
            this.props.no()
        }
        unmount(this.container)
    }

    remove=()=>{
        if(this.state.node){
            document.body.removeChild(this.state.node)
        }
    }

    static confirm(text,ok,no){
        let temNode = document.createElement("div")
        document.body.appendChild(temNode)
        render(<Confirm container={temNode} text={text} ok={ok} no={no}/>,temNode)
    }
}