import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axios } from "../../../axios.cfg";

import style from './phone.folders.module.css'


export default function PhoneNotesFolders(props){
    useEffect(()=>{
        getFolders()
    },[])

    const [folders,setFolders] = useState([])

    return <div className={style.container}>
        
        <h2>文件夹</h2>

        {folders.map((e,index)=><div key={index}>
            <Link to={`/note/${e.id}`}>{e.folder}</Link>
        </div>)}
    </div>


    function getFolders() {
        axios.get("/api/note/folders").then(({data})=>{
            console.log(data)
            if(data.status === 200){
                setFolders(data.data)
            }
        })
    }
}