import { Button, Input } from "antd"
import { useState } from "react"
import { axios } from "../../axios.cfg"
import Message from "../my-components/message"


export default function Setting(){
    if(!localStorage.getItem("user")){
        window.location.href="/"
    }

    const [module,setModule] = useState('')
    const [submodule,setSubmodule] = useState('')
    const [pass,setPass] = useState({oldPassswd:'',newPasswd:'',newPasswd2:''})

    return <div>
        <h2># Setting</h2>
        <div>
            <p>
                <span onClick={()=>setModule('')}>个人资料</span>
                <span onClick={()=>setModule('security')}>安全</span>
                <span onClick={()=>setModule('privacy')}>隐私</span>
            </p>
        </div>
        {module===''&&<div>
            profile    
        </div>}

        {module==='security'&&<div>
            security    

            <Button onClick={()=>setSubmodule('setemail')}>设置密保邮箱</Button>
            <Button onClick={()=>setSubmodule('setpassword')}>修改密码</Button>

            {submodule==='setemail'&&<div>
                <input placeholder="email" />    
            </div>}

            {submodule==='setpassword'&&<div>
                <Input addonBefore="旧密码" type="password"
                    onChange={e=>setPass({...pass,oldPassswd:e.target.value})} />
                <Input addonBefore='新密码' type="password"
                    onChange={e=>setPass({...pass,newPasswd:e.target.value})} />
                <Input addonBefore='再确认' type="password"
                    onChange={e=>setPass({...pass,newPasswd2:e.target.value})} />
                <Button onClick={submitPassword}>提交</Button>
            </div>}
        </div>}

        {module==='privacy'&&<div>
            privacy    
        </div>}

    </div>

    function submitPassword(){
        if(!pass.oldPassswd || !pass.newPasswd || !pass.newPasswd2){
            Message.fail("fuck")
            return
        }
        if(pass.newPasswd !== pass.newPasswd2){
            Message.fail("两次密码不一致")
            return
        }
        axios.put("/api/user/password",{
            oldPassswd:pass.oldPassswd,
            newPasswd:pass.newPasswd
        }).then(({data})=>{
            if(data.status === 200){
                Message.success("修改成功")
            }else{
                Message.fail(data.message)
            }
        }).catch(e=>{})
    }
}