import React, { useEffect } from "react"
import MyEditor from "../tools/editor"
import style from "./note.editor.module.css"
import {axios} from "../../axios.cfg";
import Message from "../my-components/message";
import Confirm from "../my-components/comfirm";


export default function NoteEditor(props){
    const noteId = props.id
    const [detail,setDetail] = React.useState({ 
        title:"",
        folder:"",
        content:""
    })
    useEffect(()=>{
        if(noteId){
            getNoteDetail()
        }
        //当0时，表示新建笔记
        if(noteId === 0)
            setDetail({
                id:0,
                title:"",
                folder:props.folder,
                content:""
            })
        // eslint-disable-next-line
    },[props])

    return<div>
        <div className={style.header}>
            <p>
                <span></span>
                <input placeholder="title" value={detail.title||''} onChange={titleChange} />
                <span onClick={save}>保存</span>
                <span onClick={deleteNote}>删除</span>
            </p>
        </div>
        <div className={style.editor}>
            {
                (detail.id || noteId===0)&&
                <MyEditor key={detail.id} 
                    backData={(e)=>setDetail({...detail,content:e})} 
                    initData={detail.content}/>
            }
        </div>
    </div>


    function getNoteDetail() {
        axios.get("/api/note",{
            params:{
                noteId
            }
        }).then(({data})=>{
            if(data.status === 200){
                setDetail(data.data)
            }
        }).catch(err=>{})
    }

    function titleChange(s){
        setDetail({...detail,title:s.target.value})
    }

    function save(){
        //当id不为0时，表示修改笔记
        if(detail.id){
            alter()
            return;
        }
        let content = detail.content
        let title = detail.title
        if(!content||!title){
            // Message.error("请输入内容OK？")
            return;
        }
        axios.post("/api/note",{
            id:detail.id,
            title,
            content,
            folder:detail.folder
        }).then(({data})=>{
            if(data.status === 200){
                Message.success("保存成功")
                setDetail({title:"",folder:"",content:""})
                props.getNotesOfFolder(detail.folder);
            }
        }).catch(err=>{
            Message.fail("保存失败")
        })
    }
    function alter(){
        let content = detail.content
        let title = detail.title
        if(!content||!title){
            Message.fail("标题和内容都不能为空")
            return;
        }
        axios.put("/api/note",{
            id:detail.id,
            title,
            content,
            folder:detail.folder
        }).then(({data})=>{
            if(data.status === 200){
                Message.success("保存成功")
                props.getNotesOfFolder(detail.folder);
            }
        }).catch(err=>{
            Message.fail("保存失败")
        })
    }

    function deleteNote(){
        if(!detail.id||detail.id===0){
            Message.fail("请指定要删除的记录 ok？")
            return;
        }
        Confirm.confirm("确定删除笔记？",()=>{
            axios.delete("/api/note",{
                params:{
                    id:detail.id,
                    tag:detail.folder
                }
            }).then(data=>{
                Message.success("删除成功")
                props.getNotesOfFolder(detail.folder)
            }).catch(err=>{
                console.log(err)
                Message.fail("删除失败")
            })
        },()=>{
            
        })

    }
}