
import { axios } from "../../axios.cfg";
const debug = false;


//获取用户信息 
function getLoggedUser(){
    clear()
    let user = localStorage.getItem("user");
    if(user){
        return JSON.parse(user)
    }
}

//强制规则 只有user或者只有token，全清除
function clear(){
    let user = localStorage.getItem("user");
    //没有用户信息 有token
    if(!user){
        let token;
        if(debug){
            token = localStorage.getItem("token")
            //cookie中没数据但是localstorage中有
            if(token){
                localStorage.clear()
            }
        }else{
            token = getCookie("token");
            if(token){
                localStorage.clear()
                axios.get("/logout").then(({data})=>{
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
    }else{
        //有用户信息 但是没有token
        if(debug){
            if(!localStorage.getItem("token")){
                localStorage.clear()
            }
        }else{
            let token = getCookie("token");
            if(!token){
                localStorage.clear()
            }
        }
    }
}



function getCookie(k){
    let arr = document.cookie.split(";").map(e=>{
        let tem = e.trim().split("=");
        return {
            key:tem[0],
            value:tem[1]
        }
    }).filter(e=>e.key===k)
    if (arr.length>0){
        return arr[0].value
    }
}

function checkOrDoNothing(){
    let user = getLoggedUser()
    if(user){
        return user
    }
}
function checkOrClear(){
    let user = getLoggedUser()
    if(!user){
        localStorage.clear();
    }else{
        return user;
    }
}

export {getLoggedUser,checkOrClear,clear,checkOrDoNothing,getCookie};